defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/OptionsList', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/OptionsListItem',
// eslint-disable-next-line no-unused-vars
], function (m, OptionsListItem) {
  const OptionsList = {
    controller: function ({ controller }) {
      Object.assign(this, controller);
    },
    view: function (controller) {
      return (<div data-uitest-id='systems-options-list' className={`flex p-12 gap-8 ${controller.useCompactView ? '' : 'md:flex-col'}`}>
        {controller.gameInstance().infoFeed().getSystemNames(controller.selectedSystemType()).map((systemName) => {
          return (<OptionsListItem controller={controller} systemName={systemName} />);
        })}
      </div>);
    }
  };

  return OptionsList;
});