defineDs('DanskeSpil/Domain/Lotto/Scripts/Campaigns/Templates/Christmas/SnowLayer',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {
    // Template:
    var SnowLayer = function (isHidden) {
      const flakesBasedOnWindowWidth = Math.round(window.outerWidth / 25);
      const snowFlakes = [...Array(Math.min(flakesBasedOnWindowWidth, 50)).keys()];
      // View:
      return m('.snow-wrapper', {
        class: `snow-wrapper--${isHidden ? 'hidden' : 'visible'}`
      },
      snowFlakes.map(() => m('.snow-wrapper__snowflake'))
      );
    };

    // Public functions:
    return SnowLayer;
  });
