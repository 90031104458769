defineDs('DanskeSpil/Domain/NumberGames/Scripts/Templates/Shared/Modal',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Common/Framework/EventHandling/Scripts/Event'
  ],
  function (m, Event) {
    const _Modal = {
      controller: function ({ controller }) {
        Object.assign(this, controller);

        this.pageModalsCount = document.querySelectorAll('.generic-modal').length + 1;

        this.onAnimationEnd = (event) => {
          const animationName = event.animationName;

          if (animationName === 'generic-modal-card-slide-in-animation') {
            this.actionClassName('generic-modal--active');
            Event.fire('generic-modal:shown', this);
          }

          if (animationName === 'generic-modal-fade-out-animation') {
            this.remove();
            this.isRendered(false);
            this.actionClassName('');
            m.redraw();
          }
        };
      },
      view: function (controller, ignoreArgs, slots) {
        if (!controller.isRendered()) {
          return null;
        }

        // View:
        return m('.generic-modal', {
          class: `generic-modal--${controller.pageModalsCount} ${controller.actionClassName()}`,
          onanimationend: controller.onAnimationEnd
        }, m('.generic-modal__card', [
          m('button.generic-modal__close-button', {
            onclick: controller.close
          }, m('svg.icon-x', m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-x' }))),
          m('.generic-modal__card-content', slots())
        ]));
      }
    };

    const Modal = function ({ $parent, extraClassNames } = {}) {
      this.$container = document.createElement('div');
      this.$container.classList.add('generic-modal__container');
      const slots = m.prop(null);
      let onCloseCallback = m.prop(null);
      this.isRendered = m.prop(false);
      this.isShown = m.prop(false);
      this.actionClassName = m.prop('');

      if (extraClassNames?.length > 0) {
        const classNames = extraClassNames.split(' ');
        classNames.forEach((className) => {
          this.$container.classList.add(className);
        });
      }

      this.close = (event) => {
        if (event) {
          event.stopPropagation();
          event.preventDefault();
        }
        this.actionClassName('generic-modal--active generic-modal--fade-out');
      };

      this.remove = () => {
        this.isRendered(false);
        onCloseCallback();
        this.$container.remove(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        setTimeout(() => {
          this.isShown(false);
        }, 0);
      };

      this.onClose = function (callback) {
        onCloseCallback = callback;
      };

      this.setContent = (content) => {
        this.isRendered(true);
        slots(content);
      };

      this.show = () => {
        if (this.isShown()) {
          return;
        }
        const _$parent = $parent || document.body;
        this.actionClassName('generic-modal--fade-in');
        _$parent.appendChild(this.$container);
        this.isShown(true);
      };

      m.mount(this.$container, m(_Modal, { controller: this }, slots));
    };

    // Public functions:
    return Modal;
  });
