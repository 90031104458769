defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/Classic',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Common/Framework/EventHandling/Scripts/Event',
    'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
    'DanskeSpil/Domain/NumberGames/Scripts/Helpers/LabelDictionary',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Buttons/Button',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Header/Header',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersRotarySelector/RotarySelector',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/RowsList',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/NumbersPicker'
  ],
  function (m, Event, JackpotInfo, Utils, LabelDictionary, Svg, Button, Header, RotarySelector, RowsList, NumbersPicker) {
    const ClassicGame = {
      controller: function ({ rootSettings, rootProperty, useCompactView, gameInstance, gameModel, gameName, externalBackToRowsSelectorButton = false }) {

        this.labelDictionary = LabelDictionary(rootSettings.labels);
        this.useCompactView = useCompactView || Utils.isMobile();
        this.gameName = gameName;
        this.currentRow = m.prop(this.useCompactView ? null : 1);
        this.showRowsToGenerateStep = m.prop(true);
        this.gameInstance = gameInstance;
        this.jackpotData = JackpotInfo.getByGame(this.gameName);
        this.waitingForAutofill = m.prop(false);

        const hasNumbersSelected = this.gameInstance().rows().some((row) => {
          const selectedNumbersCount = row.numbers.length;
          let selectedSpecialNumbersCount = 0;
          switch (this.gameName) {
          case 'vikinglotto':
            selectedSpecialNumbersCount = row.vikingNumber ? 1 : 0;
            break;
          case 'eurojackpot':
            selectedSpecialNumbersCount = row.starNumbers.length;
            break;
          }

          return selectedNumbersCount > 0 || selectedSpecialNumbersCount > 0;
        });
        this.showRowsToGenerateStep(!hasNumbersSelected);

        this.generateNewGameModel = () => {
          this.gameInstance(gameModel.setupGame({ playType: 'Classic' }));
          Event.fire('numbersPicker:newGameInstanceGenerated', { id: this.gameInstance().id() });
        };

        this.saveRowsToGenerate = () => {
          if (this.gameInstance().rowCount() !== this.gameInstance().rowsToGenerate()) {
            if (this.gameInstance().rowCount() > this.gameInstance().rowsToGenerate()) {
              const rowsToGenerate = this.gameInstance().rowsToGenerate();
              this.generateNewGameModel();
              this.gameInstance().rows([]);
              this.gameInstance().rowsToGenerate(rowsToGenerate);
              this.gameInstance().save();
            }
            for (let i = this.gameInstance().rowCount(); i < this.gameInstance().rowsToGenerate(); i++) {
              this.gameInstance().addRow();
            }
            this.gameInstance().save();
          }
          this.showRowsToGenerateStep(false);
        };

        this.addNewRow = () => {
          this.gameInstance().addRow();
          this.currentRow(this.gameInstance().rows().length);
          this.gameInstance().save();
        };

        this.autofillAllRows = () => {
          if (this.waitingForAutofill()) return;
          this.waitingForAutofill(true);
          this.gameInstance().autofillClassicRows()
            .then(() => {
              m.redraw();
            })
            .finally(() => {
              this.waitingForAutofill(false);
              m.redraw();
            });
        };

        rootProperty('numbersPickerCtaSlot', ({ numbersPickerGoToNextFlowStep, currentFlowStepIndex }) => {
          if (currentFlowStepIndex() !== -1 || this.gameInstance().playType() !== 'Classic') {
            return null;
          }
          const rowsToGenerate = this.gameInstance().rowsToGenerate();
          const rowPrice = this.gameInstance().rowPrice(0);
          let price = this.gameInstance().rowCount() * rowPrice;
          if (this.showRowsToGenerateStep()) {
            price = rowsToGenerate * rowPrice;
            return (
              <div className='mb-30'>
                <Button
                  data-uitest-id='go-to-next-step-button'
                  variant='primary'
                  onclick={() => {
                    this.saveRowsToGenerate();
                  }}
                >{this.labelDictionary('classicRowsToGenerateGoToNumberPickerButton', { rowsCount: rowsToGenerate, price: price })}</Button>
              </div>
            );
          }

          return (
            <div className='mb-30'>
              {this.gameInstance().areAllRowsValid() ?
                <Button
                  data-uitest-id='go-to-next-step-button'
                  variant='primary'
                  onclick={() => {
                    numbersPickerGoToNextFlowStep();
                  }}
                >{this.labelDictionary('classicNumbersPickerGoToNextButton', { rowsCount: this.gameInstance().rowCount(), price: price })}</Button> :
                <Button
                  data-uitest-id='autofill-all-rows-button'
                  variant='secondary'
                  loading={this.waitingForAutofill()}
                  onclick={this.autofillAllRows}
                >{this.labelDictionary('classicNumbersPickerAutofillAllRows', { price: price })}</Button>
              }
            </div>
          );
        });

        if (externalBackToRowsSelectorButton) {
          externalBackToRowsSelectorButton({
            isVisible: this.showRowsToGenerateStep
          });
        }

        this.numberPickerMaxWidthClass = 'max-w-[62.2rem]';
        switch (this.gameName) {
        case 'eurojackpot':
          this.numberPickerMaxWidthClass = 'max-w-[81.2rem]';
          break;
        }

        this.gameDescriptionSlot = m.prop(null);
        rootProperty('numbersPickerGameDescriptionSlot', (slot) => {
          return this.gameDescriptionSlot(slot);
        });

        Event.subscribe(`${gameName}:classic:createNewRow`, () => this.addNewRow);
      },
      view: function (controller, { gameName, rowsOptions, defaultRowCount, externalBackToRowsSelectorButton = false }) {
        const wrappingClassName = `theme-numbergames theme-${gameName}`;
        if (!controller.gameInstance().rowsToGenerate()) {
          controller.gameInstance().rowsToGenerate(defaultRowCount);
        }
        if (controller.gameInstance().rows().length === 0) {
          controller.gameInstance().addRow();
        }

        if (controller.showRowsToGenerateStep()) {
          return (<div className={wrappingClassName}>
            <Header
              useCompactView={controller.useCompactView}
              heading={controller.labelDictionary('classicRowsToGenerateHeading')}
              subheading={controller.labelDictionary('classicRowsToGenerateSubheading', {
                poolSize: `<strong class='font-extrabold text-black group-[.christmas-theme]:text-white'>${controller.jackpotData.poolSizeDecimal ? `${controller.jackpotData.poolSize}` : `min ${controller.jackpotData.minimumPoolSize}`} MIO</strong>`,
              })}
            />
            <RotarySelector
              classNames={gameName}
              gameType={gameName}
              options={rowsOptions}
              value={controller.gameInstance().rowsToGenerate}
              onchange={() => controller.gameInstance().save()}
              valuePostfix={controller.labelDictionary(controller.gameInstance().rowsToGenerate() === 1 ? 'classicRowsToGenerateRotaryLabelSingular' : 'classicRowsToGenerateRotaryLabelPlural')}
              descriptionSlot={controller.gameDescriptionSlot()}
            />
          </div>);
        }

        return (
          <div className={wrappingClassName}>
            {!externalBackToRowsSelectorButton ? <div
              className='js-numbers-picker-back-to-rows-to-generate-button cursor-pointer absolute left-16 top-40 -mt-10 md:left-54 md:top-54 md:mt-6 md:-ml-4 group-[.christmas-theme]:text-white'
              onclick={() => {
                controller.showRowsToGenerateStep(true);
              }}
            >
              <Svg
                src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#back-icon'
                width='23'
                height='14'
              />
            </div> : null}
            <Header
              useCompactView={controller.useCompactView}
              heading={controller.labelDictionary('classicNumbersPickerHeading')}
              subheading={controller.labelDictionary('classicNumbersPickerSubheading')}
            />
            <div className='flex justify-center gap-16 mt-12 mb-20'>
              <div className={`w-full relative ${controller.useCompactView ? '' : 'flex flex-col md:max-w-372'}`}>
                <div className='basis-0 grow overflow-y-auto'>
                  <RowsList controller={controller} />
                  {controller.gameInstance().rowCount() < controller.gameInstance().classicMaxRows() ? <div
                    className={`w-full bg-white/60 backdrop-blur-sm flex justify-center py-16 px-20 box-border ${controller.useCompactView ? '' : ' sticky bottom-0'} group-[.christmas-theme]:bg-lotto-christmasRed-1/60`}
                  >
                    <div
                      data-uitest-id='add-new-row-button'
                      className='flex items-center gap-8 cursor-pointer group-[.christmas-theme]:text-white'
                      onclick={controller.addNewRow}
                    >
                      <div className='w-32 h-32 rounded-full bg-yellow-1 flex justify-center items-center'>
                        <Svg
                          src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/SharedIcons.svg#plus-icon'
                          width='14'
                          height='14'
                        />
                      </div>
                      <div className='text-12 font-bold uppercase underline'>{controller.labelDictionary('classicNumbersPickerRowsListAddRow', { rowPrice: controller.gameInstance().rowPrice(0) })}</div>
                    </div>
                  </div> : null}
                </div>
              </div>
              {controller.useCompactView ? null : <div className='mt-8'>
                <NumbersPicker
                  controller={controller}
                  className={`${controller.numberPickerMaxWidthClass} p-16 rounded-16`}
                />
              </div>}
            </div>
          </div>
        );
      }
    };

    return ClassicGame;
  });