defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/Core/NumberFace',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
  ],
  // eslint-disable-next-line no-unused-vars
  function (m, Svg) {
    const NumberFace = {
      controller: function ({ controller, selected, specialNumbersType = null }) {
        Object.assign(this, controller);
        this.svgBackground = null;
        this.selectedBgColor = 'bg-lotto-red-2 group-[.christmas-theme]:bg-lotto-christmas-gold-linear-gradient';
        this.selectedTextColor = 'text-white group-[.christmas-theme]:text-lotto-christmasRed-1';
        this.unselectedTextColor = 'text-black group-[.christmas-theme]:text-white';

        switch (this.gameName) {
        case 'vikinglotto':
          this.selectedBgColor = 'bg-yellow-1';
          break;
        case 'eurojackpot':
          this.selectedBgColor = 'bg-eurojackpot-gold-1';
          break;
        }

        switch (specialNumbersType) {
        case 'starNumbers':
          this.unselectedTextColor = 'text-eurojackpot-gold-1';
          this.svgBackground = <Svg
            classNames='w-full h-full absolute inset-0'
            src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/SpecialShapes.svg#star'
            queryAttributes={{
              '.astar-path': {
                fill: selected ? '#E79D24' : 'transparent',
                stroke: '#E79D24',
              }
            }}
          />;
          break;
        case 'vikingNumber':
          this.unselectedTextColor = 'text-white';
          this.selectedTextColor = 'text-yellow-1';
          this.svgBackground = <Svg
            classNames={`w-full h-full absolute inset-0 ${selected ? 'text-yellow-1' : 'text-white'}`}
            src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/SpecialShapes.svg#viking'
          />;
          break;
        }
      },
      view: function (controller, { key, value, selected, canBePressed, specialNumbersType }) {
        let numberClassName = `relative text-16 leading-22 text-center font-bold backface-hidden transition ${selected ? controller.selectedTextColor : controller.unselectedTextColor}`;
        if (canBePressed) {
          numberClassName += ' group-hover/number-cell:scale-110';
        }

        if (controller.svgBackground) {
          switch (specialNumbersType) {
          case 'starNumbers':
            numberClassName += ' top-2';
          }
          return (
            <div
              data-uitest-id={`special-inner-number-cell-${value}`}
              key={key}
              className={`${controller.useCompactView ? 'w-40 h-40' : 'w-54 h-54'} flex justify-center items-center absolute top-0 left-0 backface-hidden`}
            >
              <div className='w-full h-full absolute top-0 left-0'>{controller.svgBackground}</div>
              <div className={numberClassName}>{value}</div>
            </div>
          );
        }
        return (
          <div
            data-uitest-id={`number-inner-cell-${value}`}
            key={key}
            className={`${controller.useCompactView ? 'w-40 h-40' : 'w-54 h-54'} flex justify-center items-center absolute top-0 left-0 rounded-full backface-hidden ${selected ? controller.selectedBgColor : 'bg-white group-[.christmas-theme]:bg-lotto-christmas-red-radial-gradient'}`}
          >
            <div className={numberClassName}>{value}</div>
          </div>
        );
      }
    };

    return NumberFace;
  });