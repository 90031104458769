defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Buttons/Button', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
],
// eslint-disable-next-line no-unused-vars
function (m) {
  const Button = {
    controller: function () {
      return {};
    },
    view: function (ignoreController, { variant, tag, onClick = () => {}, classNames = '', disabled = false, loading = false, ...attributes }, children) {
      const styles = ['group/button text-12 font-extrabold uppercase text-black relative'];
      const backdropStyles = ['absolute inset-0 rounded-28 transition'];
      const foregroundStyles = ['absolute rounded-28 user-select-none transition'];

      const variants = {
        primary: 'py-22 px-32 justify-center',
        secondary: 'py-22 px-32 justify-center',
      };

      if (variant) {
        if (!variants.hasOwnProperty(variant)) {
          throw new Error(`Invalid button variant '${variant}'.`);
        }
        switch (variant) {
        case 'primary':
          styles.push('group-[.christmas-theme]:text-lotto-christmasRed-1');
          backdropStyles.push('bg-yellow-1 group-[.christmas-theme]:bg-lotto-christmas-gold-linear-gradient-2');
          foregroundStyles.push('group-[.christmas-theme]:inset-2 group-[.christmas-theme]:border group-[.christmas-theme]:border-solid group-[.christmas-theme]:border-lotto-christmasRed-1');
          break;
        case 'secondary':
          backdropStyles.push('bg-white border border-black border-solid');
          break;
        }
        styles.push(variants[variant]);
      }

      if (loading) {
        styles.push('');
      }

      if (classNames) {
        styles.push(classNames);
      }

      if (disabled) {
        styles.push('text-grey-4 cursor-not-allowed');
        backdropStyles.push('!bg-grey-3');
      } else {
        styles.push('cursor-pointer');
        foregroundStyles.push('group-hover/button:scale-105');
        backdropStyles.push('group-hover/button:scale-105');
      }

      let Tag = tag || 'div';

      return (
        <Tag
          onclick={!loading ? onClick : ''}
          className={styles.join(' ')}
          {...attributes}>
          <span className={backdropStyles.join(' ')} aria-hidden='true' />
          <div className='relative flex justify-center items-center gap-10'>
            <div className='mx-auto'>{children}</div>
          </div>
          <span className={foregroundStyles.join(' ')} aria-hidden='true' />
        </Tag>
      );
    }
  };

  return Button;
});