defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/RowItem',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/DeleteRowModal',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Classic/NumbersPicker',
  ], function (m, Svg, DeleteRowModal, NumbersPicker) {
    const RowItem = {
      controller: function ({ controller, row, useCompactView }) {
        Object.assign(this, controller);

        this.rowNumber = row.count + 1;
        this.$parent = m.prop(null);
        this.showExtraOptions = m.prop(false);
        this.specialNumbersType = null;
        this.specialNumbersIcon = null;
        this.specialNumbersPerRow = null;
        this.deleteRowModal = new DeleteRowModal({ controller: this, row, useCompactView });
        this.selectRow = () => {
          if ((this.currentRow() === this.rowNumber) && useCompactView) {
            this.currentRow(null);
            return;
          }
          this.currentRow(this.rowNumber);
        };

        this.showDeleteModal = () => {
          if (!useCompactView) {
            this.selectRow();
          }
          this.deleteRowModal.show();
        };

        switch (this.gameName) {
        case 'vikinglotto':
          this['bg-1'] = 'bg-vikinglotto-blue-1';
          this['bg-3/80'] = 'bg-vikinglotto-blue-3/80';
          this['bg-3'] = 'bg-vikinglotto-blue-3';
          this['border-3'] = 'border-vikinglotto-blue-3';
          this.specialNumbersType = 'vikingNumber';
          this.specialNumbersPerRow = this.gameInstance().vikingNumbersPerRowMax();
          this.specialNumbersIcon = <Svg
            classNames='absolute left-1/2 -translate-x-1/2 -translate-y-10'
            width='4rem'
            height='4rem'
            src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/SpecialShapes.svg#viking'
          />;
          break;
        case 'eurojackpot':
          this['bg-1'] = 'bg-eurojackpot-carbon-1';
          this['bg-3/80'] = 'bg-eurojackpot-gold-2/80';
          this['bg-3'] = 'bg-eurojackpot-gold-2';
          this['border-3'] = 'border-eurojackpot-gold-2';
          this.specialNumbersType = 'starNumbers';
          this.specialNumbersPerRow = this.gameInstance().starNumbersPerRowMax();
          this.specialNumbersIcon = <Svg
            classNames='absolute left-1/2 -translate-x-1/2 -translate-y-12'
            width='4rem'
            height='4rem'
            src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/SpecialShapes.svg#star'
            queryAttributes={{
              '.astar-path': {
                fill: 'black',
                stroke: 'black',
              }
            }}
          />;
          break;
        default:
          this['bg-1'] = 'bg-lotto-red-1';
          this['bg-3/80'] = 'bg-lotto-red-3/80';
          this['bg-3'] = 'bg-lotto-red-3';
          this['border-3'] = 'border-lotto-red-3';
          break;
        }

        this.settingUpParent = ($element, isInitialized) => {
          if (isInitialized) {
            return;
          }
          this.$parent($element);
        };
      },
      view: function (controller, { row, useCompactView }) {
        const isRowSelected = controller.currentRow() === controller.rowNumber;
        const numbersCount = row.numbers.length;
        let specialNumbersCount = 0;
        if (controller.specialNumbersType) {
          switch (controller.specialNumbersType) {
          case 'starNumbers':
            specialNumbersCount = row.starNumbers.length;
            break;
          case 'vikingNumber':
            specialNumbersCount = row.vikingNumber ? 1 : 0;
            break;
          }
        }
        const isRowEmpty = (numbersCount + specialNumbersCount) === 0;
        const isRowValid = isRowEmpty ? false : Boolean(controller.gameInstance().isRowValid(controller.rowNumber));

        let rowContent = controller.labelDictionary('classicNumbersPickerRowsListRowIsEmpty');
        if (!isRowEmpty) {
          rowContent = row.numbers.map((numberItem) => {
            const numberValue = numberItem.number < 10 ? `0${numberItem.number}` : numberItem.number;
            return <span className='w-20 flex items-center text-center'>{numberValue}</span>;
          });
          if (!isRowValid) {
            const remainingUnfilledNumbers = controller.gameInstance().numbersPerRow() - numbersCount;
            for (let i = 0; i < remainingUnfilledNumbers; i++) {
              rowContent.push(<span className='w-20 flex items-center text-center'>-</span>);
            }
          }
          if (controller.specialNumbersType) {
            const specialNumberStructure = (content) => {
              const textColor = controller.specialNumbersType === 'starNumbers' ? 'text-white' : 'text-black';
              return <span className='relative top-2 w-34 text-center ml-6 -mr-6'>
                {controller.specialNumbersIcon}
                <span className={`relative flex items-center justify-center py-2 ${textColor}`}>{content}</span>
              </span>;
            };
            const specialNumbersArray = [];
            for (let i = 0; i < specialNumbersCount; i++) {
              if (controller.specialNumbersType === 'vikingNumber') {
                specialNumbersArray.push(row[controller.specialNumbersType]);
                break;
              }
              specialNumbersArray.push(row[controller.specialNumbersType][i]);
            }
            rowContent.push(specialNumbersArray.map((specialNumberItem) => {
              const specialNumberValue = specialNumberItem.number < 10 ? `0${specialNumberItem.number}` : specialNumberItem.number;
              return specialNumberStructure(specialNumberValue);
            }));
            const remainingUnfilledSpecialNumbers = controller.specialNumbersPerRow - specialNumbersCount;
            for (let i = 0; i < remainingUnfilledSpecialNumbers; i++) {
              rowContent.push(specialNumberStructure('-'));
            }
          }
        }

        let wrapperClassName = 'relative flex-1 flex flex-col items-center cursor-pointer rounded-12 border border-solid overflow-clip transition';
        let innerWrapperClassName = 'w-full text-black box-border py-18 px-16 flex gap-16 rounded-12 border border-solid transition';

        if (controller.rowNumber % 2 === 0) {
          wrapperClassName += ` bg-white ${(isRowSelected) ? 'border-gray-200' : 'border-transparent'}`;
          innerWrapperClassName += ` bg-gray-100/20 ${(isRowSelected) ? (useCompactView ? 'rounded-b-none border-gray-200' : 'border-gray-200') : 'border-gray-100'}`;
        } else {
          wrapperClassName += ` ${controller['border-3']} ${(isRowSelected) ? controller['bg-3'] : controller['bg-3/80']}`;
          innerWrapperClassName += ` ${controller['border-3']} ${(isRowSelected) ? (useCompactView ? 'rounded-b-none' : controller['bg-3']) : ''}`;
        }

        if ((isRowSelected && !useCompactView)) {
          wrapperClassName += ' scale-105';
        }

        if (controller.showExtraOptions()) {
          wrapperClassName += ' -translate-x-40';
        }

        return (
          <div
            data-uitest-id={`row-item-${controller.rowNumber}`}
            className='flex items-start'
            config={controller.settingUpParent}
          >
            <div
              className={wrapperClassName}
              isRowValid={isRowValid}
              isRowSelected={isRowSelected}
            >
              <div
                data-uitest-id='row-item-clickable'
                className={innerWrapperClassName}
                onclick={controller.selectRow}
              >
                <span className='font-bold leading-8'>{controller.rowNumber}.</span>
                <div className='flex gap-10 font-normal'>{rowContent}</div>
                {useCompactView ? <div
                  className={`inline-flex ml-auto transition ${isRowSelected ? 'rotate-180' : 'rotate-0'}`}
                >
                  <Svg
                    src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#arrow-down-icon'
                    classNames='pointer-events-none'
                    width={20}
                    height={20}
                  />
                </div> : <div
                  data-uitest-id='delete-row-button'
                  className='cursor-pointer font-bold ml-auto hover:scale-125'
                  onclick={controller.showDeleteModal}
                >
                  <Svg
                    src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/SharedIcons.svg#close-icon'
                    width={18}
                    height={18}
                  />
                </div>}
              </div>
              {(useCompactView && isRowSelected) ? <div className={`${controller['bg-1']} w-full flex justify-center`}>
                <NumbersPicker
                  controller={controller}
                  className='max-w-[28rem] pt-24 pb-8'
                />
              </div> : null}
            </div>
            {useCompactView ? <div className='relative h-60 flex items-center'>
              <div
                className='text-grey-2'
              >
                <div
                  className={`absolute h-60 top-0 right-12 px-4 flex items-center transition delay-75 text-black ${controller.showExtraOptions() ? '' : 'delay-0 scale-75 opacity-0 pointer-events-none'}`}
                  onclick={() => {
                    if (!controller.showExtraOptions()) {
                      return;
                    }
                    controller.showDeleteModal();
                  }}
                >
                  <Svg
                    src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/SharedIcons.svg#trash-bin-icon'
                    height='24'
                    width='21'
                  />
                </div>
                <div
                  className='pl-4'
                  onclick={() => controller.showExtraOptions(!controller.showExtraOptions())}
                >
                  <svg xmlns='http://www.w3.org/2000/svg' width='4' height='20' viewBox='0 0 4 20' fill='none'>
                    <circle cx='2' cy='2' r='2' fill='currentColor'/>
                    <circle cx='2' cy='10' r='2' fill='currentColor'/>
                    <circle cx='2' cy='18' r='2' fill='currentColor'/>
                  </svg>
                </div>
              </div>
            </div> : null}
          </div>
        );
      }
    };

    return RowItem;
  });