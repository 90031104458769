defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/System', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/LabelDictionary',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Buttons/Button',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Header/Header',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/SelectorScreen',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/SelectorBar',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/TabsWrapper/TabsWrapper',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/System/OptionsList',
], function (m, Event, JackpotInfo, Utils, LabelDictionary, Svg, Button, Header, SelectorScreen, SelectorBar, TabsWrapper, OptionsList) {
  const SystemGame = {
    controller: function ({ rootSettings, rootProperty, gameInstance, useCompactView, gameName, systemsTypes = [], externalBackToRowsSelectorButton = false }) {

      this.labelDictionary = LabelDictionary(rootSettings.labels);
      this.useCompactView = useCompactView || Utils.isMobile();
      this.systemsTypes = systemsTypes;
      this.selectedSystemType = m.prop(null);
      this.gameName = gameName;
      this.currentRow = m.prop(1);
      this.showSystemSelectorScreen = m.prop(true);
      this.gameInstance = gameInstance;
      this.jackpotData = JackpotInfo.getByGame(this.gameName);

      const currentRowData = this.gameInstance().getRow(this.currentRow());
      const selectedNumbersCount = currentRowData.numbers.length;
      let selectedSpecialNumbersCount = 0;
      switch (this.gameName) {
      case 'vikinglotto':
        selectedSpecialNumbersCount = currentRowData.vikingNumber ? 1 : 0;
        break;
      case 'eurojackpot':
        selectedSpecialNumbersCount = currentRowData.starNumbers.length;
        break;
      }
      this.showSystemSelectorScreen(selectedNumbersCount === 0 && selectedSpecialNumbersCount === 0);

      this.selectedSystemType(this.gameInstance().systemName().slice(0, 1));

      this.onSelectSystemName = (systemName) => {
        this.gameInstance().systemName(systemName);
        this.gameInstance().resetRow(1);
        Event.fire('ds.event.numbersPicker:onSystemNameChange', systemName);
      };

      this.onSelectSystemType = (systemType) => {
        this.selectedSystemType(systemType);
        const defaultSystemName = this.gameInstance().infoFeed().getSystemNames(systemType)[0];
        this.onSelectSystemName(defaultSystemName);
        this.showSystemSelectorScreen(false);
      };

      rootProperty('numbersPickerCtaSlot', ({ numbersPickerGoToNextFlowStep, currentFlowStepIndex }) => {
        if (currentFlowStepIndex() !== -1 || this.gameInstance().playType() !== 'System') {
          return null;
        }
        const rowsToGenerate = parseInt(this.gameInstance().getSystemRowAmount(), 10);
        const rowPrice = this.gameInstance().rowPrice(0);
        const price = rowsToGenerate * rowPrice;
        if (this.showSystemSelectorScreen() && !this.useCompactView && this.systemsTypes.length > 1) {
          return (
            <div />
          );
        }

        const isRowsValid = this.gameInstance().isRowValid(this.currentRow());
        return (
          <div className='mb-30'>
            {isRowsValid ? <Button
              data-uitest-id='go-to-next-step-button'
              variant='primary'
              onclick={() => {
                numbersPickerGoToNextFlowStep();
              }}
            >{this.labelDictionary('systemNumbersPickerRowGoToNextButton', { rowsCount: rowsToGenerate, price: price })}</Button> : <Button
              data-uitest-id='autofill-all-rows-button'
              variant='secondary'
              onclick={() => {
                this.gameInstance().autogenerateNumbers(this.currentRow()).then(() => {
                  this.gameInstance().save();
                  m.redraw();
                });
              }}
            >{this.labelDictionary('systemNumbersPickerRowAutofillAllRows', { rowsCount: rowsToGenerate, price: price })}</Button>}
          </div>
        );
      });

      if (externalBackToRowsSelectorButton) {
        externalBackToRowsSelectorButton({
          isVisible: this.showSystemSelectorScreen
        });
      }

      this.numberPickerMaxWidthClass = 'max-w-[58.2rem]';
      switch (this.gameName) {
      case 'eurojackpot':
        this.numberPickerMaxWidthClass = 'max-w-[75rem]';
        break;
      }

      this.gameDescriptionSlot = m.prop(null);
      rootProperty('numbersPickerGameDescriptionSlot', (slot) => {
        return this.gameDescriptionSlot(slot);
      });
    },
    view: function (controller, { gameName, externalBackToRowsSelectorButton = false }) {
      const wrappingClassName = `theme-numbergames theme-${gameName}`;
      if (!externalBackToRowsSelectorButton &&
          controller.showSystemSelectorScreen() &&
          !controller.useCompactView &&
          controller.systemsTypes.length > 1) {
        return (<div className={wrappingClassName}>
          <Header
            useCompactView={controller.useCompactView}
            heading={controller.labelDictionary('systemSelectorScreenHeading')}
            subheading={controller.labelDictionary('systemSelectorScreenSubheading', {
              poolSize: `<strong class='font-extrabold text-black group-[.christmas-theme]:text-white'>${controller.jackpotData.poolSizeDecimal ? `${controller.jackpotData.poolSize}` : `min ${controller.jackpotData.minimumPoolSize}`} MIO</strong>`,
            })}
          />
          <SelectorScreen
            controller={controller}
          />
        </div>);
      }

      return (<div className={wrappingClassName}>
        {(!controller.useCompactView && controller.systemsTypes.length > 1) ? <div
          className='js-numbers-picker-back-to-rows-to-generate-button cursor-pointer absolute left-16 top-40 -mt-10 md:left-54 md:top-54 md:mt-6 md:-ml-4 group-[.christmas-theme]:text-white'
          onclick={() => {
            controller.showSystemSelectorScreen(true);
          }}
        >
          <Svg
            src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#back-icon'
            width='23'
            height='14'
          />
        </div> : null}
        <Header
          useCompactView={controller.useCompactView}
          heading={controller.labelDictionary('systemNumbersPickerHeading')}
          subheading={controller.labelDictionary('systemNumbersPickerSubheading')}
        />
        {controller.systemsTypes.length > 1 ? <SelectorBar controller={controller} /> : null}
        <div className={`flex flex-col justify-center gap-16 mt-12 mb-20 ${controller.useCompactView ? '' : 'md:flex-row'}`}>
          <div className={`w-full relative flex flex-col overflow-x-auto ${controller.useCompactView ? '' : 'md:max-w-372'}`}>
            <div className={`basis-0 grow ${controller.useCompactView ? '' : 'md:overflow-y-auto'}`}>
              <OptionsList controller={controller} />
            </div>
          </div>
          <TabsWrapper controller={controller} />
        </div>
        {controller.gameDescriptionSlot?.() ? <div className='mt-16 group-[.christmas-theme]:brightness-200'>{controller.gameDescriptionSlot()}</div> : null}
      </div>);
    }
  };

  return SystemGame;
});