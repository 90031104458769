defineDs('DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Lucky/Lucky', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/LabelDictionary',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Svg/Svg',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/Shared/Buttons/Button',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Header/Header',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersRotarySelector/RotarySelector',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/NumbersPicker/PlayTypes/Lucky/NumbersPicker',
], function (m, JackpotInfo, Utils, LabelDictionary, Svg, Button, Header, RotarySelector, NumbersPicker) {
  const LuckyGame = {
    controller: function ({ rootSettings, rootProperty, useCompactView, gameInstance, gameName, externalBackToRowsSelectorButton = false }) {

      this.labelDictionary = LabelDictionary(rootSettings.labels);
      this.gameName = gameName;
      this.useCompactView = useCompactView || Utils.isMobile();
      this.currentRow = m.prop(1);
      this.showRowsToGenerateStep = m.prop(true);
      this.gameInstance = gameInstance;
      this.jackpotData = JackpotInfo.getByGame(this.gameName);

      const currentRowData = this.gameInstance().getRow(this.currentRow());
      const selectedNumbersCount = currentRowData.numbers.length;
      let selectedSpecialNumbersCount = 0;
      switch (this.gameName) {
      case 'vikinglotto':
        selectedSpecialNumbersCount = currentRowData.vikingNumber ? 1 : 0;
        break;
      case 'eurojackpot':
        selectedSpecialNumbersCount = currentRowData.starNumbers.length;
        break;
      }
      this.showRowsToGenerateStep(selectedNumbersCount === 0 && selectedSpecialNumbersCount === 0);

      rootProperty('numbersPickerCtaSlot', ({ numbersPickerGoToNextFlowStep, currentFlowStepIndex }) => {
        if (currentFlowStepIndex() !== -1 || this.gameInstance().playType() !== 'Lucky') {
          return null;
        }
        const rowsToGenerate = this.gameInstance().rowsToGenerate();
        const rowPrice = this.gameInstance().rowPrice(0);
        const price = rowsToGenerate * rowPrice;
        if (this.showRowsToGenerateStep()) {
          return (
            <div className='mb-30'>
              <Button
                data-uitest-id='go-to-next-step-button'
                variant='primary'
                onclick={() => {
                  this.gameInstance().rowsToGenerate(rowsToGenerate);
                  this.gameInstance().save();
                  this.showRowsToGenerateStep(false);
                }}
              >{this.labelDictionary('luckyNumbersPickerGoToNextButton', { rowsCount: rowsToGenerate, price: price })}</Button>
            </div>
          );
        }

        const isRowsValid = this.gameInstance().getRow(this.currentRow()).numbers.length > 0;
        return (
          <div className='mb-30'>
            <Button
              data-uitest-id='go-to-next-step-button'
              variant='primary'
              disabled={!isRowsValid}
              onclick={() => {
                numbersPickerGoToNextFlowStep();
              }}
            >{this.labelDictionary('luckyNumbersPickerGoToNextButton', { rowsCount: rowsToGenerate, price: price })}</Button>
          </div>
        );
      });

      if (externalBackToRowsSelectorButton) {
        externalBackToRowsSelectorButton({
          isVisible: this.showRowsToGenerateStep
        });
      }

      this.numberPickerMaxWidthClass = 'max-w-[62.2rem]';
      switch (this.gameName) {
      case 'eurojackpot':
        this.numberPickerMaxWidthClass = 'max-w-[81.2rem]';
        break;
      }

      this.gameDescriptionSlot = m.prop(null);
      rootProperty('numbersPickerGameDescriptionSlot', (slot) => {
        return this.gameDescriptionSlot(slot);
      });
    },
    view: function (controller, { gameName, rowsOptions, defaultRowCount, externalBackToRowsSelectorButton = false }) {
      const wrappingClassName = `theme-numbergames theme-${gameName}`;
      if (!controller.gameInstance().rowsToGenerate()) {
        controller.gameInstance().rowsToGenerate(defaultRowCount);
      }
      if (controller.showRowsToGenerateStep()) {
        return (<div className={wrappingClassName}>
          <Header
            useCompactView={controller.useCompactView}
            heading={controller.labelDictionary('luckyRowsToGenerateHeading')}
            subheading={controller.labelDictionary('luckyRowsToGenerateSubheading', {
              poolSize: `<strong class='font-extrabold text-black group-[.christmas-theme]:text-white'>${controller.jackpotData.poolSizeDecimal ? `${controller.jackpotData.poolSize}` : `min ${controller.jackpotData.minimumPoolSize}`} MIO</strong>`,
            })}
          />
          <RotarySelector
            classNames={gameName}
            gameType={gameName}
            options={rowsOptions}
            value={controller.gameInstance().rowsToGenerate}
            onchange={() => controller.gameInstance().save()}
            valuePostfix={controller.labelDictionary(controller.gameInstance().rowsToGenerate() === 1 ? 'luckyRowsToGenerateRotaryLabelSingular' : 'luckyRowsToGenerateRotaryLabelPlural')}
            descriptionSlot={controller.gameDescriptionSlot()}
          />
        </div>);
      }

      return (<div className={wrappingClassName}>
        {!externalBackToRowsSelectorButton ? <div
          className='js-numbers-picker-back-to-rows-to-generate-button cursor-pointer absolute left-16 top-40 -mt-10 md:left-54 md:top-54 md:mt-6 md:-ml-4 group-[.christmas-theme]:text-white'
          onclick={() => {
            controller.showRowsToGenerateStep(true);
          }}
        >
          <Svg
            src='/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/MultiClientCommonIcons.svg#back-icon'
            width={23}
            height={14}
          />
        </div> : null}
        <Header
          useCompactView={controller.useCompactView}
          heading={controller.labelDictionary('luckyNumbersPickerHeading')}
          subheading={controller.labelDictionary('luckyNumbersPickerSubheading')}
        />
        <div className='flex justify-center mt-12 mb-20 px-16'>
          <NumbersPicker
            controller={controller}
            className={`${controller.numberPickerMaxWidthClass} p-16 rounded-16`}
          />
        </div>
      </div>);
    }
  };

  return LuckyGame;
});